<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 -960 960 960"
  fill="#018786"
  width="38.8px"
  height="38.8px"
  ><path
    d="M460-171.462v-297.076L200-619.077v283.231q0 6.154 3.077 11.538 3.077 5.385 9.231 9.231L460-171.462Zm40 0 247.692-143.615q6.154-3.846 9.231-9.231Q760-329.692 760-335.846v-283.231L500-468.538v297.076Zm-52.308 38.924L192.307-279.692q-15.153-8.692-23.73-23.616Q160-318.231 160-335.615v-288.77q0-17.384 8.577-32.307 8.577-14.924 23.73-23.616l255.385-147.154q15.154-8.692 32.308-8.692t32.308 8.692l255.385 147.154q15.153 8.692 23.73 23.616Q800-641.769 800-624.385v288.77q0 17.384-8.577 32.307-8.577 14.924-23.73 23.616L512.308-132.538q-15.154 8.692-32.308 8.692t-32.308-8.692ZM628.462-589 737-651.462 492.308-793.077q-6.154-3.846-12.308-3.846t-12.308 3.846l-95.692 55L628.462-589ZM480-502.923l108-62.692-257-148.539-108 62.692 257 148.539Z"
  /></svg
>
