import App from "~/App.svelte";

// polyfill for Object.fromEntries()
if (!Object.fromEntries) {
  Object.fromEntries = function (entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        "Object.fromEntries() requires a single iterable argument",
      );
    }
    const obj = {};
    for (const [key, value] of entries) {
      obj[key] = value;
    }
    return obj;
  };
}

// polyfill for Object.hasOwn()
if (!("hasOwn" in Object)) {
  // @ts-ignore
  Object.hasOwn = Object.call.bind(Object.hasOwnProperty);
}

const app = new App({
  target: document.getElementById("app"),
});

export default app;
