<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { _ } from "svelte-i18n";

  /** @type {boolean} */
  export let open;
  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;
</script>

<Dialog
  bind:open
  aria-labelledby="package-drop-place-info-dialog-title"
  aria-describedby="package-drop-place-info-dialog-content"
  style="margin-top: 50px; max-height: 90%"
>
  <Title id="package-drop-place-info-dialog-title"
    >{$_("pages.Tracking.PackageDropPlaceInfoDialog.title")}</Title
  >
  <Content id="package-drop-place-info-dialog-content">
    <div>
      {#if result.actualPackageDropPlace !== 3}
        {$_("pages.Tracking.PackageDropPlaceInfoDialog.description1")}
      {:else}
        {$_("pages.Tracking.PackageDropPlaceInfoDialog.description2")}
      {/if}
    </div>
    {#if Number.isInteger(result.packageDropPlace) && Number.isInteger(result.actualPackageDropPlace)}
      <table>
        <tr>
          <th
            >{$_(
              "pages.Tracking.PackageDropPlaceInfoDialog.packageDropPlace",
            )}</th
          >
          <td>{$_(`messages.packageDropPlace.${result.packageDropPlace}`)}</td>
        </tr>
        <tr>
          <th
            >{$_(
              "pages.Tracking.PackageDropPlaceInfoDialog.actualPackageDropPlace",
            )}</th
          >
          <td
            >{$_(
              `messages.packageDropPlace.${result.actualPackageDropPlace}`,
            )}</td
          >
        </tr>
      </table>
    {/if}
  </Content>
  <Actions>
    <Button on:click={() => (open = false)}>
      <Label
        >{$_(
          "pages.Tracking.PackageDropPlaceInfoDialog.closeButtonLabel",
        )}</Label
      >
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  table {
    width: 90%;
    margin: 10px auto;
  }
  th,
  td {
    width: 45%;
    padding: 6px 6px 6px 12px;
    text-align: left;
    border: 1px solid #999;
  }
  th {
    background-color: #f5f5f5;
  }
  @media screen and (max-width: 809px) {
    th,
    td {
      border-top: none;
      display: block;
      width: calc(100% - 20px);
    }
    th {
      padding: 5px 12px;
    }
    td {
      padding: 8px 12px;
    }
    tr:first-of-type th {
      border-top: 1px solid #999;
    }
  }
</style>
