import { format as formatDate } from "date-fns";
import ky from "ky";

import backendApiMocker from "~/libs/backendApiMocker";

/**
 * @typedef {{
 *   data: *,
 *   error?: ErrorResponse,
 * }} JsonResponse
 *
 * @typedef {{
 *   title: string,
 *   message: string,
 *   details: {[key: string]: object},
 * }} ErrorResponse
 *
 * @typedef {object} ReceiverIdentification
 * @property {string} name 荷受人氏名
 * @property {string} postcode 荷受人郵便番号
 * @property {string} tel 荷受人電話番号
 *
 * @typedef {object} PushNotificationMessage
 * @property {string} title 通知タイトル
 * @property {string} body 通知本文
 *
 * @typedef {object} DriverNotificationData
 * @property {PushNotificationMessage} message お知らせ記録用メッセージ
 * @property {string} trackingNumber 送り状番号
 * @property {import("~/libs/commonTypes").DateAndTimeFrame} adjustedRedeliveryDatetime 再配達希望日時
 *
 * @typedef {PushNotificationMessage & {
 *   data: DriverNotificationData,
 * }} PushNotification
 */

/** リクエスト・操作を特定するIDのprefix **/
const operationIdPrefix = `${formatDate(new Date(), "MMddHHmm")}-${(
  import.meta.env.VITE_COMMIT_HASH || "NA"
).substring(0, 7)}-`;

/** @type {number} リクエスト・操作を特定するIDの連番 */
let operationSequenceNumber = 0;

/** @type {import("ky").KyInstance} */
let kyInstance;

const backendApi = {
  /**
   * @param {string} apiToken
   * @param {string} referrerParam
   */
  initialize: (apiToken, referrerParam) => {
    kyInstance = createInstance(apiToken, referrerParam);
  },

  /**
   * 配送情報取得
   * @param {string} trackingNumber
   * @param {ReceiverIdentification} [receiverIdentification]
   * @returns {Promise<import("~/libs/commonTypes").TrackingResult>}
   */
  async getShipment(trackingNumber, receiverIdentification) {
    /** @type {import("ky").Options} */
    let kyOptions;
    if (receiverIdentification) {
      kyOptions = { searchParams: receiverIdentification };
    }

    /** @type {JsonResponse} */
    const response = await kyInstance
      .get(`shipments/${trackingNumber}`, kyOptions)
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 再配達希望日時の更新
   * @param {string} trackingNumber
   * @param {import("~/libs/commonTypes").DateAndTimeFrame} desiredRedeliveryDatetime
   * @param {PushNotification} pushNotification
   * @param {ReceiverIdentification} [receiverIdentification]
   */
  async updateDesiredRedeliveryDatetime(
    trackingNumber,
    desiredRedeliveryDatetime,
    pushNotification,
    receiverIdentification,
  ) {
    /** @type {import("ky").Options} */
    let kyOptions;
    kyOptions = {
      json: {
        specifiedPickupDatetime: {
          desiredRedeliveryDatetime: desiredRedeliveryDatetime,
        },
        pushNotification: pushNotification,
      },
    };
    if (receiverIdentification) {
      kyOptions.searchParams = receiverIdentification;
    }
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`shipments/${trackingNumber}/specified-pickup-datetime`, kyOptions)
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 再配達の受け取り可能時間帯の更新
   * @param {string} trackingNumber
   * @param {Array<import("~/libs/commonTypes").DateAndTimeFrame>} availablePickupDatetime
   * @param {ReceiverIdentification} [receiverIdentification]
   */
  async updateAvailablePickupDatetime(
    trackingNumber,
    availablePickupDatetime,
    receiverIdentification,
  ) {
    /** @type {import("ky").Options} */
    let kyOptions;
    kyOptions = {
      json: { availablePickupDatetime: availablePickupDatetime },
    };
    if (receiverIdentification) {
      kyOptions.searchParams = receiverIdentification;
    }
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`shipments/${trackingNumber}/specified-pickup-datetime`, kyOptions)
      .json();
    assertNotErrorResponse(response);
  },
};
export default backendApi;

/**
 * バックエンドからerrorプロパティを持つレスポンスを受け取った場合に発生する例外
 */
export class ErrorResponseException extends Error {
  errorResponse;

  /**
   * @param {ErrorResponse} errorResponse
   */
  constructor(errorResponse) {
    super("Receive error response");
    this.name = "ErrorResponseException";
    this.errorResponse = errorResponse;
  }
}

/**
 * kyのインスタンスを生成する。
 * @param {string} apiToken
 * @param {string} referrerParam
 * @returns {import("ky").KyInstance}
 */
function createInstance(apiToken, referrerParam) {
  return ky.extend({
    prefixUrl: import.meta.env.VITE_API_SERVER_URL,
    timeout: 15000,
    parseJson: (text) => (text !== "" ? JSON.parse(text) : null),
    hooks: {
      beforeRequest: [
        (request, options) => {
          if (apiToken) {
            request.headers.set("X-API-Token", apiToken);
          }

          if (referrerParam) {
            request.headers.set("X-REFERRER-PARAM", referrerParam);
          }

          // リクエスト・操作を特定するIDを設定
          request.headers.set(
            "X-Operation-Id",
            operationIdPrefix + ++operationSequenceNumber,
          );

          // モック用のリクエスト書き換え処理
          _REMOVABLE_MOCK_: {
            if (import.meta.env.VITE_USE_MOCK_API) {
              backendApiMocker.prepareRequest(request, options);
            }
            break _REMOVABLE_MOCK_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
          }
        },
      ],

      beforeError: [
        async (error) => {
          if (
            error.response?.headers
              ?.get("Content-Type")
              ?.match(/^application\/json(?:;|$)/)
          ) {
            try {
              /** @type {ErrorResponse} */
              const errorResponse = (await error.response.json())?.error;
              if (errorResponse) {
                error["errorResponse"] = errorResponse;
              }
            } catch (error) {
              console.error(error);
            }
          }
          return error;
        },
      ],
    },
  });
}

/**
 * errorプロパティを持つレスポンスでないことをアサートする。
 * @param {JsonResponse} response
 */
function assertNotErrorResponse(response) {
  if (response.error) {
    throw new ErrorResponseException(response.error);
  }
}
