import { tick } from "svelte";

import LoadingProgress from "~/components/LoadingProgress.svelte";

/**
 * @typedef {string} LoadingMessage 読込中に表示するメッセージ（HTMLタグ可）。省略時はデフォルトのメッセージが表示される
 */

/** LoadingProgressコンポーネントのインスタンス @type {LoadingProgress} */
let instance;

/** LoadingProgressの表示を要求している数 */
let holdCount = 0;

const loadingProgress = {
  /**
   * 指定した関数の実行～終了にあわせてLoadingProgressを表示する。
   * @template T
   * @param {() => T extends Promise ? never : T} fn
   * @param {LoadingMessage} [message]
   * @returns {() => T extends Promise ? never : T}
   */
  wrap(fn, message) {
    return () => {
      show(message);
      try {
        return fn();
      } finally {
        hide();
      }
    };
  },

  /**
   * 指定した非同期関数の実行～終了にあわせてLoadingProgressを表示する。
   * @template T
   * @param {() => Promise<T>} fn
   * @param {LoadingMessage} [message]
   * @returns {() => Promise<T>}
   */
  wrapAsync(fn, message) {
    return async () => {
      show(message);
      try {
        return await fn();
      } finally {
        hide();
      }
    };
  },

  /**
   * LoadingProgressのメッセージを更新する。
   * @param {string} message 読込中に表示するメッセージ（HTMLタグ可）
   */
  updateMessage(message) {
    if (instance) {
      instance.message = message;
    }
  },

  /**
   * LoadingProgressを表示中か否かを返す。
   * @returns {boolean} LoadingProgressを表示中か否か
   */
  isShowing() {
    return !!instance;
  },
};

export default Object.freeze(loadingProgress);

/**
 * LoadingProgressを表示する。
 * 既に表示されていて、かつ異なるメッセージが指定されている場合はメッセージを更新する。
 * @param {LoadingMessage} [message]
 */
function show(message) {
  holdCount++;
  if (instance) {
    if (instance.message !== message) {
      instance.message = message;
    }
  } else {
    tick().then(() => {
      if (!instance) {
        instance = new LoadingProgress({
          target: document.body,
          props: {
            message: message,
          },
        });
      }
    });
  }
}

/**
 * LoadingProgressの表示を終了する。
 */
function hide() {
  if (--holdCount > 0) {
    return;
  }
  if (instance) {
    instance?.$destroy?.();
    instance = undefined;
  }
}
