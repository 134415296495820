<script>
  import qs from "qs";
  import { onMount } from "svelte";

  import Header from "~/pages/Main/Header.svelte";
  import Tracking from "~/pages/Tracking.svelte";

  // SvelteのonMountイベントハンドラ
  onMount(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    console.log("[Main.svelte] Query Parameters:", queryParams);
  });
</script>

<Header />
<main>
  <div class="contentsArea">
    <Tracking />

    <div style="height: 40px;" />
    <div class="hline" />

    <div class="footerArea">
      <p class="copyright">
        © {new Date().getFullYear()} Contract / Software Consultant Corporation
      </p>
    </div>
  </div>
</main>

<style>
  main {
    padding-bottom: 40px;
  }
  .contentsArea {
    min-width: 340px;
    max-width: 950px;
    margin: 0 auto;
    box-sizing: border-box;
    border-top-width: 0;
    font-size: 15px;
    line-height: 1.6em;
  }
  .hline {
    background-color: #01878786;
    height: 1px;
  }
  .footerArea {
    padding: 15px;
    text-align: center;
  }

  @media screen and (min-width: 810px) {
    .contentsArea {
      width: 90%;
      padding: 20px 50px 0px 50px;
      border: 1px solid #bdbdbd87;
      border-radius: 0 0 10px 10px;
    }
    .copyright {
      font-size: small;
    }
  }

  @media screen and (max-width: 809px) {
    .contentsArea {
      padding: 10px 20px 0px 20px;
    }
    .copyright {
      font-size: small;
    }
  }
</style>
