import { toast as svelteToast } from "@zerodevx/svelte-toast";

export const toast = Object.freeze({
  /**
   * エラーToastを表示する。
   * @param {string} msg
   * @param {import("@zerodevx/svelte-toast/stores").SvelteToastOptions} [options]
   * @returns {number}
   */
  error(msg, options) {
    options = initOptions(options);
    options.theme["--toastBackground"] = "#bd362f";
    return svelteToast.push(msg, options);
  },

  /**
   * INFO Toastを表示する。
   * @param {string} msg
   * @param {import("@zerodevx/svelte-toast/stores").SvelteToastOptions} [options]
   * @returns {number}
   */
  info(msg, options) {
    options = initOptions(options);
    options.theme["--toastBackground"] = "#2f96b4";
    return svelteToast.push(msg, options);
  },

  /**
   * Toastを削除する。
   * @param {(number | {[key: string]: string})} [id]
   * @returns {object}
   */
  pop(id) {
    return svelteToast.pop(id);
  },
});

/**
 * @param {import("@zerodevx/svelte-toast/stores").SvelteToastOptions} [options]
 * @returns {import("@zerodevx/svelte-toast/stores").SvelteToastOptions}
 */
function initOptions(options) {
  if (!options) {
    options = {};
  }
  if (!options.theme) {
    options.theme = {};
  }
  return options;
}
