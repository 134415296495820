<script>
  import { _, locale } from "svelte-i18n";

  import CardboardIcon from "~/components/icons/cardboard.svelte";
  import { ShippingStatus } from "~/libs/commonTypes";
  import { formatDate } from "~/libs/dateUtils";

  /** @type {import("~/libs/commonTypes").ShippingEvent} */
  export let event = null;
  /** @type {import("~/libs/commonTypes").ReturnInfo} */
  export let returnInfo = null;
  /** @type {boolean} */
  export let isLatestEvent;

  const time = event ? event.time : returnInfo.time;
</script>

<div class="event">
  <div class={!isLatestEvent ? "current" : " current latest"}>
    {#if !isLatestEvent}
      <span class="material-icons md-40 mainColor">keyboard_arrow_down</span>
    {:else if event}
      <CardboardIcon />
    {:else}
      <span class="material-icons md-return mainColor">do_disturb</span>
    {/if}
  </div>
  <div class="info">
    <div class="statusArea">
      <p class="status">
        {#if event}
          {$_(`messages.status.${event.status}`)}
        {:else}
          {$_(`messages.status.return`)}
        {/if}
      </p>
    </div>
    <div class="timeArea">
      <p class="time">
        <span
          class="formatedDate {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
          >{formatDate(
            time,
            $_("config.defaultDateFormat"),
            $locale,
          )}{#if event && $_("config.nameSuffix") === "En"},{/if}</span
        ><span
          class="formatedTime {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
          >{formatDate(time, $_("config.defaultTimeFormat"), $locale)}</span
        >
      </p>
    </div>
    {#if event && event.status != ShippingStatus.CREATED && event.status != ShippingStatus.DELIVERED && event["location" + $_("config.nameSuffix")] != undefined}
      <div class="locationArea">
        <p class="location">{event["location" + $_("config.nameSuffix")]}</p>
      </div>
    {/if}
  </div>
</div>
{#if !isLatestEvent}
  <div class="connectingLine" />
{/if}

<style lang="scss">
  .event {
    display: flex;

    :global(.svg-icons-chevron-down),
    :global(.svg-icons-cardboard) {
      fill: #018786;
    }
  }
  .current {
    box-sizing: border-box;
    border: 1px solid #bdbdbd87;
    background-color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #bdbdbd87;
    background-color: white;
  }
  .statusArea {
    width: 100%;
  }
  .timeArea {
    width: 130px;
    min-width: 150px;
  }
  .locationArea {
    width: 100%;
    max-width: 300px;
    display: flex;
  }
  .connectingLine {
    width: 5px;
    background-color: #0187874f;
  }
  .material-icons.md-40 {
    font-size: 40px;
  }
  .material-icons.mainColor {
    color: #018786;
  }
  .formatedDate {
    margin-right: 0.2em;
  }
  @media screen and (min-width: 810px) {
    .current {
      width: 55px;
      height: 50px;
      border-radius: 5px 5px 5px 5px;
      padding: 5px;

      :global(.svg-icons-cardboard) {
        padding: 5px;
      }
    }
    .info {
      margin: 3px 0px 0px -1px;
      display: flex;
      padding: 5px;
      height: 44px;
      border-radius: 0px 10px 10px 0px;
    }
    .statusArea {
      margin: auto 0;
      padding-left: 5px;
      max-width: 150px;
    }
    .status {
      font-size: 17px;
    }
    .timeArea {
      margin: auto 0;
      padding-left: 5px;
    }
    .locationArea {
      margin: auto 0;
      padding-left: 5px;
    }
    .connectingLine {
      margin-left: 24px;
      height: 5px;
    }
    .material-icons.md-return {
      font-size: 36px;
      margin: 0 2px;
    }
    .formatedDate {
      display: inline-block;
      text-align: right;
    }
    .formatedDate.ja {
      width: 4.2em;
    }
    .formatedDate.en {
      width: 3.7em;
    }
    .formatedTime {
      display: inline-block;
      text-align: right;
    }
    .formatedTime.ja {
      width: 2.5em;
    }
    .formatedTime.en {
      width: 4.3em;
    }
  }
  @media screen and (max-width: 809px) {
    .current {
      margin-top: 13px;
      margin-right: -5px;
      width: 44px;
      height: 40px;
      border-radius: 5px 5px 5px 5px;
      padding: 1px;
      z-index: 1;

      :global(.svg-icons-cardboard) {
        padding: 4px;
      }
    }
    .latest {
      width: 48px;
    }
    .info {
      margin-left: -0.8px;
      margin-bottom: 5px;
      display: column;
      padding: 2px;
      height: 65px;
      border-radius: 5px 5px 5px 5px;
    }
    .statusArea {
      margin-top: 5px;
      padding-left: 12px;
      width: 100%;
    }
    .status {
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
    }
    .timeArea {
      margin-top: 4px;
      padding-left: 20px;
    }
    .time {
      font-size: 13px;
      line-height: 1.3;
    }
    .locationArea {
      margin-top: 2px;
      padding-left: 20px;
    }
    .location {
      font-size: 13px;
      line-height: 1;
    }
    .connectingLine {
      transform: translate(18px, 20px);
      margin-top: -44px;
      height: 43px;
    }
    .material-icons.md-return {
      font-size: 32px;
      margin: 0 4px;
    }
  }
</style>
