/**
 * エラーハンドリングのために使用するユーザ定義例外クラス。
 * メッセージにはユーザに表示可能なメッセージを設定する。
 */
export class HandledError extends Error {
  constructor(message) {
    super(message);
    this.name = "HandledError";
  }
}

/**
 * 追跡情報クラス
 */
export class TrackingData {
  /**
   * 表示順序（1からの連番）
   * @type {number}
   */
  order;

  /**
   * 追跡番号
   * @type {string}
   */
  trackingNumber;

  /**
   * 追跡結果情報
   * @type {TrackingResult}
   */
  result;
}

/**
 * @typedef {{
 *   events: Array<ShippingEvent>,
 *   extraEvents: Array<ExtraEvent>,
 *   return: ReturnInfo,
 *   deliveryDate: string,
 *   desiredDate: string,
 *   desiredTime: string,
 *   numberOfPackages: number,
 *   isExistsRedeliveryContext: boolean,
 *   packageDropPlace: number,
 *   actualPackageDropPlace: number,
 *   url: string,
 *   podPhotoUrl: string,
 *   deliveryBoxNumber: string,
 *   deliveryBoxPin: string,
 *   companyId: number,
 *   companyNameJa: string,
 *   companyNameEn: string,
 *   selectableDeliveryMethod: Array<number>,
 *   damaged: boolean,
 *   redeliveryContext: RedeliveryContext,
 *   specifiedPickupDatetime: SpecifiedPickupDatetime,
 * }} TrackingResult 追跡結果情報
 *
 * @typedef {{
 *   time: string,
 *   locationJa: string,
 *   locationEn: string,
 *   status: number,
 * }} ShippingEvent 配送イベント
 *
 * @typedef {{
 *   time: string,
 *   division: number,
 *   extraEventType: number,
 *   messageJa: string,
 *   messageEn: string
 * }} ExtraEvent 特殊配送イベント
 *
 * @typedef {{
 *   returnReason: number,
 *   time: string
 * }} ReturnInfo 返品情報
 *
 * @typedef {{
 *   date: string,
 *   timeFrame: string
 * }} DateAndTimeFrame 日付と時間帯のセット
 *
 * @typedef {{
 *   redeliveryDatetimeSpecMethod: number,
 *   timeFramePreset: Array<string>,
 *   redeliveryUnavailability: Array<DateAndTimeFrame>,
 *   adjustedRedeliveryDatetime: DateAndTimeFrame,
 *   notificationResend: boolean
 * }} RedeliveryContext ドライバーが指定する再配達に関連するパラメータ
 *
 * @typedef {{
 *   desiredRedeliveryDatetime: DateAndTimeFrame,
 *   availablePickupDatetime: Array<DateAndTimeFrame>
 * }} SpecifiedPickupDatetime 荷受人が指定する再配達に関連するパラメータ
 */

/**
 * 配送ステータスクラス
 */
export class ShippingStatus {
  /**
   * 0: 出荷待ち
   * @type {number}
   * @readonly
   */
  static CREATED = 0;
  /**
   * 2: 輸送中
   * @type {number}
   * @readonly
   */
  static IN_TRANSIT = 1;
  /**
   * 3: 保管中
   * @type {number}
   * @readonly
   */
  static HELD_IN_DEPOT = 2;
  /**
   * 3: 持出中
   * @type {number}
   * @readonly
   */
  static OUT_FOR_DELIVERY = 3;
  /**
   * 4: 配達完了
   * @type {number}
   * @readonly
   */
  static DELIVERED = 4;
}

/**
 * 配達場所クラス
 */
export class DropOffLocation {
  /**
   * 0: 玄関ドア前
   * @type {number}
   * @readonly
   */
  static BY_THE_DOOR = 0;
  /**
   * 1: 宅配ボックス
   * @type {number}
   * @readonly
   */
  static LOCKER = 1;
  /**
   * 2: メーターボックス
   * @type {number}
   * @readonly
   */
  static METERBOX = 2;
  /**
   * 3: 郵便受け
   * @type {number}
   * @readonly
   */
  static MAILBOX = 3;
  /**
   * 4: エントランス前
   * @type {number}
   * @readonly
   */
  static ENTRANCE = 4;
  /**
   * 5: 対面
   * @type {number}
   * @readonly
   */
  static FACE_TO_FACE = 5;
}

/**
 * 返品理由クラス
 */
export class ReturnReason {
  /**
   * 0: EC事業者から返品要求
   * @type {number}
   * @readonly
   */
  static REQUESTED_RETURN_FROM_EC = 0;
  /**
   * 1: 荷受人から注文キャンセル要求
   * @type {number}
   * @readonly
   */
  static REQUESTED_CANCEL_FROM_RECEIVER = 1;
  /**
   * 2: 受け取り拒否
   * @type {number}
   * @readonly
   */
  static ACCEPT_DENIED = 2;
  /**
   * 3: 宛先住所間違い
   * @type {number}
   * @readonly
   */
  static ADDRESS_WRONG = 3;
  /**
   * 4: 宛先住所不明
   * @type {number}
   * @readonly
   */
  static ADDRESS_UNKNOWN = 4;
  /**
   * 5:再配達回数上限超過
   * @type {number}
   * @readonly
   */
  static REDELIVERY_LIMIT_EXCEEDED = 5;
  /**
   * 6: 荷物に問題が発生した
   * @type {number}
   * @readonly
   */
  static SHIPMENT_PROBLEM = 6;
}

/**
 * 再配達日時指定方法の区分値クラス
 */
export class RedeliveryDatetimeSpecMethod {
  /**
   * 0: 指定日時を1つ指定
   * @type {number}
   * @readonly
   */
  static DESIRED_DATE_AND_TIME = 0;
  /**
   * 1: 1週間分の受け取り可能時間帯を指定
   * @type {number}
   * @readonly
   */
  static AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK = 1;
}
