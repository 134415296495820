<script>
  import Button, { Label } from "@smui/button";
  import MenuSurface from "@smui/menu-surface";
  import { locale } from "svelte-i18n";

  import HeaderBanner from "~/pages/Main/HeaderBanner.svelte";

  /** @type {import("@smui/menu-surface").default} */
  let languageMenu;
  /** @type {string} */
  let changeLanguageLabel = $locale === "ja" ? "日本語" : "English";
</script>

<header>
  <div class="headerLeftArea">
    <HeaderBanner />
  </div>
  <div class="headerRightArea">
    <div class="changeLanguage">
      <div class="changeLanguageCaption">
        <span class="material-icons md-18 mainColor">language</span>
        <span class="changeLanguageCaptionText">Language</span>
      </div>
      <Button
        color="secondary"
        on:click={() => languageMenu.setOpen(true)}
        style="height: 20px;"
      >
        <Label>{changeLanguageLabel}</Label>
        <span class="material-icons md-18 mainColor">expand_more</span>
      </Button>
      <MenuSurface bind:this={languageMenu} anchorCorner="BOTTOM_LEFT">
        <div>
          <Button
            color="secondary"
            on:click={() => {
              locale.set("en");
              changeLanguageLabel = "English";
              languageMenu.setOpen(false);
              localStorage.setItem("preferredLocale", "en");
            }}
          >
            <Label>English</Label>
          </Button>
          <Button
            color="secondary"
            on:click={() => {
              locale.set("ja");
              changeLanguageLabel = "日本語";
              languageMenu.setOpen(false);
              localStorage.setItem("preferredLocale", "ja");
            }}
          >
            <Label>日本語</Label>
          </Button>
        </div>
      </MenuSurface>
    </div>
  </div>
</header>

<style lang="scss">
  header {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0px 0.1px 5px 0px rgba(0, 0, 0, 0.147);
    width: 96%;
    height: 70px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255);
    border-radius: 0px 0px 30px 30px;
  }
  .headerLeftArea {
    display: flex;
    gap: 10px;
    margin: auto auto auto 0;
    padding-left: 30px;

    :global(.logo) {
      height: 54px;
    }
    :global(.title) {
      height: 46px;
      margin-top: 4px;
    }
  }
  .headerRightArea {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }
  .changeLanguage {
    margin-right: 20px;
    text-align: center;

    .changeLanguageCaption {
      color: #018786;
      display: flex;
      align-items: center;

      .changeLanguageCaptionText {
        font-size: 0.8em;
        margin-left: 2px;
      }
    }

    :global(.svg-icons-web) {
      width: 20px;
      height: 20px;
      fill: #018786;
    }
    :global(.svg-icons-chevron-down) {
      width: 20px;
      height: 20px;
      fill: #018786;
    }
    > :global(.mdc-button) {
      --mdc-text-button-container-height: 28px;
      padding: 0 0 0 4px;
    }
    :global(.mdc-button__label) {
      text-transform: none;
    }
    > :global(.mdc-button .mdc-button__label) {
      letter-spacing: normal;
      padding-left: 3px;
      font-size: 0.9em;
    }
    :global(.mdc-menu-surface > div) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0.5em;
    }
    :global(.mdc-menu-surface .mdc-button) {
      width: 100%;
      padding: 0 1em;
    }
  }
  .material-icons.mainColor {
    color: #018786;
  }

  @media screen and (max-width: 809px) {
    header {
      border-radius: 0px 0px 20px 20px;
      width: 100%;
      height: 56px;
    }
    .headerLeftArea {
      gap: 6px;
      padding-left: 15px;

      :global(.logo) {
        height: 36px;
      }
      :global(.title) {
        height: 30px;
        margin-top: 3px;
      }
    }
    .changeLanguage {
      margin-right: 10px;

      :global(.svg-icons-web) {
        width: 16px;
        height: 16px;
      }
      :global(.svg-icons-chevron-down) {
        width: 16px;
        height: 16px;
      }
      > :global(.mdc-button) {
        --mdc-text-button-container-height: 22px;
        padding-left: 1px;
      }
      > :global(.mdc-button .mdc-button__label) {
        font-size: 0.75em;
      }
    }
  }
</style>
