<script>
  import { escape } from "html-escaper";
  import { _, locale } from "svelte-i18n";

  import { formatDate } from "~/libs/dateUtils";

  /** @type {import("~/libs/commonTypes").ExtraEvent} */
  export let trouble = null;
  /** @type {import("~/libs/commonTypes").ReturnInfo} */
  export let returnInfo = null;

  const messageKey = trouble
    ? `messages.trouble.${trouble.extraEventType}`
    : `messages.return.${returnInfo.returnReason}`;
  const time = trouble ? trouble.time : returnInfo.time;
</script>

<div class="trouble">
  <div class="troubleLine1">
    <div class="troubleTime">
      <span
        class="formatedDate {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
        >{formatDate(
          time,
          $_("config.defaultDateFormat"),
          $locale,
        )}{#if $_("config.nameSuffix") === "En"},{/if}</span
      ><span
        class="formatedTime {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
        >{formatDate(time, $_("config.defaultTimeFormat"), $locale)}</span
      >
    </div>
  </div>
  <div class="troubleLine2">
    <div class="troubleMessage">
      {#if trouble}
        <!-- 「messageKey」はja.json/en.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
        {@html trouble["message" + $_("config.nameSuffix")]
          ? escape(trouble["message" + $_("config.nameSuffix")]).replace(
              /\n/g,
              "<br />",
            )
          : $_(messageKey)}
      {:else}
        <!-- ja.json/en.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
        {@html $_(messageKey)}
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .troubleLine1 {
    display: flex;
  }
  .troubleMessage {
    width: 100%;
  }
  .formatedDate {
    margin-right: 0.2em;
  }
  @media screen and (min-width: 810px) {
    .trouble {
      display: flex;
      margin-top: 4px;
    }
    .troubleTime {
      min-width: max-content;
      padding-right: 15px;
    }
    .formatedDate {
      display: inline-block;
      text-align: right;
    }
    .formatedDate.ja {
      width: 4.2em;
    }
    .formatedDate.en {
      width: 3.7em;
    }
    .formatedTime {
      display: inline-block;
      text-align: right;
    }
    .formatedTime.ja {
      width: 2.5em;
    }
    .formatedTime.en {
      width: 4.3em;
    }
  }
  @media screen and (max-width: 809px) {
    .trouble {
      display: column;
      margin-top: 8px;
      line-height: initial;
    }
    .troubleTime {
      min-width: max-content;
      font-weight: bold;
    }
  }
</style>
