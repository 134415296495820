<script>
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { _, addMessages, init as i18nInit } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import i18n_en from "~/locales/en.json";
  import i18n_ja from "~/locales/ja.json";
  import Main from "~/pages/Main.svelte";

  let preferredLocale = localStorage.getItem("preferredLocale");
  switch (preferredLocale) {
    case "ja":
    case "en":
      break;
    default:
      preferredLocale = null;
      localStorage.removeItem("preferredLocale");
      break;
  }

  addMessages("ja", i18n_ja);
  addMessages("en", i18n_en);
  i18nInit({
    fallbackLocale: preferredLocale ?? "ja",
  });

  // API認証トークンの保存（.区切りで複数のトークンが含まれている場合があるため、最大トークン長の36文字でのチェックは実施しない）
  const apiToken = location.hash.substring(1);
  // どのメールから追跡ページに遷移したかを表すパラメータ
  const referrerParam = new URLSearchParams(location.search).get("p");

  // kyの初期化
  backendApi.initialize(apiToken, referrerParam);
</script>

<svelte:head>
  <title>{$_("pages.common.titleTag")}</title>
</svelte:head>

<Main />

<SvelteToast
  options={{
    duration: 7500,
    pausable: true,
  }}
/>
